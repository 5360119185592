import React from 'react';
import styled from 'styled-components';
import qs from 'query-string';
import * as AppActions from '../../AppActions';
import {useOutlet} from 'reconnect.js';
import * as ApiUtil from 'rev.sdk.js/Utils/ApiUtil';
import DataJson from '../../../data.json';

export default function JoinsPrintPreview(props) {
  const [user] = useOutlet('user');
  const params = qs.parse(props.location.search);
  const {type = 'course'} = params;
  const [records, setRecords] = React.useState(null);

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      try {
        let {joins, lectures} = await ApiUtil.req(
          `${DataJson.apiHost}/study/joins?token=${user.token}`,
          {
            method: 'POST',
            data: {
              first_years: [],
            },
          },
        );

        joins = joins.map((it) => {
          return {
            id: it._id.$oid,
            ...it,
          };
        });

        lectures = lectures.map((it) => {
          return {
            id: it._id.$oid,
            ...it,
          };
        });

        if (type === 'lecture') {
          setRecords(lectures);
        } else {
          const matchKeywords = {
            course: '課程',
            activity: '活動',
          };

          setRecords(
            joins.filter((it) => {
              for (const l of it.labels || []) {
                if (l.indexOf(matchKeywords[type]) > -1) {
                  return true;
                }
              }
              return false;
            }),
          );
        }
      } catch (err) {
        console.warn(err);
      }
      AppActions.setLoading(false);
    }

    if (user) {
      fetchData();
    }
  }, [user, type]);

  if (records === null) {
    return <Wrapper />;
  }

  function groupToN(records, n) {
    const groups = [];
    const nextRecords = [...records];
    while (nextRecords.length > n) {
      groups.push(nextRecords.slice(0, n));
      nextRecords.splice(0, n);
    }
    if (nextRecords.length > 0) {
      groups.push(nextRecords.slice());
    }
    return groups;
  }

  const recordGroups = groupToN(records, 10);

  if (recordGroups.length === 0) {
    return (
      <div style={{maxWidth: 600, margin: '80px auto'}}>
        <h2 style={{textAlign: 'center'}}>查無紀錄</h2>
      </div>
    );
  }

  return (
    <Wrapper>
      {recordGroups.map((group, idx) => (
        <div className="page" key={idx}>
          <h1>課程活動核定參與紀錄 {`(${idx + 1}/${recordGroups.length})`}</h1>
          <div className="content">
            <h3>會員姓名：{user.data?.name || '---'}</h3>
            {group.map((it, j) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: 10,
                    borderBottom: '1px solid #ccc',
                  }}
                  key={j}>
                  <div style={{flex: 1}}>{it.name}</div>
                  <div>{`核給時數：${it.tba_hours || 0}小時`}</div>
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  border: 1px solid #fafafa;
  background-color: #fafafa;
  margin: 0;
  padding: 0;

  & > .page {
    width: 210mm;
    min-height: 297mm;
    padding: 20mm;
    margin: 10mm auto;
    border: 1px #d3d3d3 solid;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

    & > h1 {
      margin-bottom: 30px;
      text-align: center;
    }

    & > div.content {
      margin-bottom: 50px;

      & > h3 {
        margin-bottom: 5px;
      }
    }

    & > div.sign {
      & > h2 {
        margin-bottom: 10px;
      }
    }
  }

  @page {
    size: A4 portrait;
    margin: 0 auto;
  }

  @media print {
    html,
    body {
      width: 210mm;
      height: 297mm;
    }
    .page {
      margin: 0;
      border: initial;
      border-radius: initial;
      width: initial;
      min-height: initial;
      box-shadow: initial;
      background: initial;
      page-break-after: always;

      :last-child {
        page-break-after: avoid;
      }
    }
  }
`;
